<template>
  <div class="faq-wrap">
    <div class="w-full max-w-rifyo mx-auto pt-14 md:pt-24 pb-20">
      <div class="w-full md:w-2/3 mx-auto max-xl:px-7 overflow-hidden">
        <div class="relative mt-20 mb-10">
          <h1 class="page-title__faq">FAQ</h1>
        </div>
        <div class="loading mx-auto flex justify-center p-12" v-if="isLoading">
          <Loading />
        </div>
        <div v-else class="flex flex-wrap flex-row overflow-x-auto gap-y-4 -mx-4 mb-8 md:mb-10 justify-center">
          <div
            v-for="faq in faqList"
            :key="faq.id"
            :id="faq.category_faqs"
            class="min-w-[200px] px-4 text-center tabs-category"
            >
            <button
            @click="updateTabs(faq.id)"
            :class="
              tabs == faq.id
                ? 'tabs-category-text-item category-active'
                : 'tabs-category-text-item'
            ">
              {{ faq.category_faqs }}
            </button>
          </div>
        </div>
        <template v-if="tabContent">
          <div
            v-for="content in tabContent"
            :key="content.id">
            <accordion class="border-b border-[#eae3d9] mb-4">
              <template v-slot:title>
                <div
                  class="tabs-title"
                  v-html="content.question"
                ></div>
              </template>
              <template v-slot:content>
                <div
                  class="tabs-description pb-4"
                  v-html="content.answer"
                ></div>
              </template>
            </accordion>
          </div>
        </template>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/footer';
  import Api from '../api';
  import XClose from '../components/x-close';
  import Loading from '../components/Loading.vue';
  import Accordion from "../components/accordion";

  export default {
    name: 'FAQ',
    props: {},
    data() {
      return {
        faqData: [],
        faqList: [],
        tabs: null,
        tabContent: [],
        msg_err: null,
        isError: false,
        isLoading: false,
        tabQuery: null,
      };
    },
    created() {
      // this.getfaq();
      this.getFaqCategory(this.$route.query.tab);
    },
    methods: {
      closeMsg() {
        this.msg_err = null;
        this.isError = false;
      },
      async getFaqCategory(id) {
        try {
          const response = await this.$http.get(Api.faqCategoryUrl);
          this.faqList = response.data.data;
          this.tabs = id;
          this.tabQuery = id;
          this.getFaqContent(id);
        } catch (error) {
          console.log(error);
        }
      },
      async getFaqContent(category_id) {
        try {
          let fullUrl = Api.faqUrl;
          if (category_id) {
            fullUrl = fullUrl + `/${category_id}`;
          }
          const response = await this.$http.get(fullUrl);
          this.tabContent = response.data.data;
        } catch (error) {
          console.log(error);
        }
      },
      updateTabs(tabs) {
        this.tabs = tabs;
        this.getFaqContent(tabs);
      },
      htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
      }
    },
    components: {
      Accordion,
      Footer,
      XClose,
      Loading,
    },
  };
</script>

<style scoped>
.page-title__faq {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
}

.tabs-category-text-item {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  opacity: 0.6;
}

.tabs-category:not(:first-of-type) {
  border-left: 1px solid #eae3d9;
}

.category-active {
  opacity: 1;
}

.tabs-title {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px; 
  text-align: left;
}

@media (max-width:767px) {
  .page-title__faq {
    font-size: 36px;
    line-height: 46px;
  }
}
</style>
